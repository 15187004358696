import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typo } from '../../utilities/CustomComponents';
import { MdAddCircle as AddIcon } from 'react-icons/md';
import EditNoteIcon from '@mui/icons-material/EditNote';
import useStyles from './SectionContent.styles';
import StickyNoteIcon from '@mui/icons-material/StickyNote2';

const SectionContent = (props) => {
  const classes = useStyles(props.bgcolor);
  return (
    <Box sx={classes.container}>
      <Box sx={classes.titleWrapper}>
        <Typo sx={props.titleStyle ? props.titleStyle : classes.title} variant={'body1'}>
          {props.title}
        </Typo>
        {props.withNotes && !props.readOnlyNotes && (
          <Box
            sx={classes.editBtnContainer}
            onClick={props.addNotes ? () => props.addNotes() : undefined}>
            Add notes <StickyNoteIcon fontSize='small' sx={{ height: '14px' }} />
          </Box>
        )}
        {props.editBtn ? (
          <Box
            sx={props.editBtnContainer ? props.editBtnContainer : classes.editBtnContainer}
            hidden={!props.isEdit}
            onClick={() => props.onClick()}>
            {props.btnTitle} {props.isAddIcon ? <AddIcon /> : <EditNoteIcon fontSize='small' />}
          </Box>
        ) : (
          <Box hidden={!props.isEdit} sx={classes.btn} onClick={() => props.onClick()}>
            New entry <AddIcon />
          </Box>
        )}
      </Box>
      <Box sx={props.childStyle ? props.childStyle : classes.wrapper}>{props.children}</Box>
    </Box>
  );
};

SectionContent.propTypes = {
  title: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isAddIcon: PropTypes.bool,
  onClick: PropTypes.func,
  bgcolor: PropTypes.string,
  childStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  editBtnContainer: PropTypes.object,
  btnTitle: PropTypes.string,
  editBtn: PropTypes.bool,
  withNotes: PropTypes.bool,
  readOnlyNotes: PropTypes.bool,
  addNotes: PropTypes.func,
};

SectionContent.defaultProps = {
  editBtn: false,
  isAddIcon: false,
  withNotes: false,
  readOnlyNotes: true,
};

export default SectionContent;
