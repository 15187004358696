import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDefaultInfo } from '../../redux/actions/index';
import Login from './Login';
import LoginErrorMsg from './LoginErrorMsg';
import MagicUrlViewState from '../app/lcert/MagicUrlViewState';
import SelfRegistration from '../app/registration/SelfRegistration';
import axios from 'axios';
import SitePermissionTypes from '../../redux/const/sitepermission';

const PalmettoAuthV2 = ({ children }) => {
  const dispatch = useDispatch();
  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMagicURL, setIsMagicURL] = useState(false);
  // const [token, setToken] = useState(null);
  // const [id, setID] = useState(null);
  const [magicData, setMagicData] = useState({});
  const [isSelfRegister, setIsSelfRegister] = useState(false);

  /**
   * Referenced from https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript/8486188
   */
  const getJsonFromUrl = (hashBased) => {
    window.location.hash = '';
    let query;
    if (hashBased) {
      let pos = window.location.href.indexOf('?');
      if (pos === -1) return [];
      query = window.location.href.substring(pos + 1);
    } else {
      query = window.location.search.substring(1);
    }
    let result = {};
    query.split('&').forEach(function (part) {
      if (!part) return;
      part = part.split('+').join(' '); // replace every + with space, regexp-free version
      let eq = part.indexOf('=');
      let key = eq > -1 ? part.substring(0, eq) : part;
      let val = eq > -1 ? decodeURIComponent(part.substring(eq + 1)).replace('#', '') : ''; // Removes a hash symbol
      let from = key.indexOf('[');
      if (from === -1) result[decodeURIComponent(key)] = val;
      else {
        let to = key.indexOf(']', from);
        let index = decodeURIComponent(key.substring(from + 1, to));
        key = decodeURIComponent(key.substring(0, from));
        if (!result[key]) result[key] = [];
        if (!index) result[key].push(val);
        else result[key][index] = val;
      }
    });
    return result;
  };

  const getPermissionsForSite = (requestedSite, requestedGroupId, data) => {
    let sitePermissions = [];
    if (!data) {
      return sitePermissions;
    }
    for (let i = 0; i < data.length; i++) {
      let attributes = data[i];
      let site = attributes && Object.keys(attributes) && Object.keys(attributes)[0];
      if (requestedSite === site) {
        for (let j = 0; j < Object.values(attributes)[0].length; j++) {
          let attribute = Object.values(attributes)[0][j];
          let key = Object.keys(attribute)[0];
          // noinspection JSCheckFunctionSignatures
          if (!isNaN(key)) {
            // Group ID
            let groupId = parseInt(key);
            let status = 'DNY';
            if (requestedGroupId === groupId) {
              for (let k = 0; k < Object.values(attribute)[0].length; k++) {
                let values = Object.values(attribute)[0][k];
                if (values && Object.keys(values) && Object.keys(values)[0]) {
                  if (Object.keys(values)[0] === 'S') {
                    status = Object.values(values)[0];
                  } else if (Object.keys(values)[0] === 'P') {
                    sitePermissions = Object.values(values)[0];
                    break;
                  }
                }
              }
              break;
            }
            //console.log("SITE: " + site + " GROUP ID: " + groupId + " STATUS: " + status + " PERMISSIONS: " + JSON.stringify(sitePermissions));
          }
          // else {
          //   let status = Object.values(attribute)[0];
          //   //console.log("SITE: " + site + " STATUS: " + status);
          // }
        }
        break;
      }
    }
    return sitePermissions;
  };

  const processLogin = async () => {
    let userInfoData = {
      id: null,
      pvMultipleUser: null,
      pvAdministrator: null,
      pvAccountLocked: null,
      pvPersonGivenName: null,
      pvPersonMiddleName: null,
      pvPersonSurName: null,
      pvOfficePhone: null,
      pvMobilePhone: null,
      pvOrganizationUnitName: null,
      username: null,
      email: null,
      status: null,
      lastUpdated: null,
      pvDomainID: null,
      pvVoid: null,
      pvCanSendBlastEmail: null,
      subscribeToEmail: false,
      accessToken: null,
      pvActiveGroupID: null,
      attributes: [],
      currentGroups: [],
      currentPositions: [],
    };
    const currentUrl = window.location.href;
    const position = currentUrl.search('self-register');
    if (position > -1) {
      setIsSelfRegister(true);
    }
    const params = new URLSearchParams(window.location.search);
    const tokenParams = params?.get('access_token');
    const idParams = params?.get('id');

    if (tokenParams && idParams > 0) {
      // setToken(tokenParams);
      // setID(idParams);
      setIsMagicURL(true);
      const payload = { token: tokenParams, id: idParams };
      return sessionStorage.setItem('magicToken', JSON.stringify(payload));
    }

    if (sessionStorage.getItem('magicToken')) {
      try {
        let authObj = JSON.parse(sessionStorage.getItem('magicToken'));
        if (authObj.token !== null && authObj.id !== null) {
          console.log('Trigger Success Magic');
          setIsMagicURL(true);
          setMagicData(authObj);
        }
      } catch (error) {
        console.error(error);
        setIsMagicURL(false);
        setLoginSuccessful(false);
      }
    }

    let parameters = getJsonFromUrl(window.location);
    // let magicUrl = false;
    let repUserType;

    /**
     * To allow a user to log in into palmetto, we need their access_token, user_id, group_id and narrative_id.
     * This would give us an ids to get the right information based on counties and narrative data
     *
     */
    if (parameters['id_token']) {
      const headers = {
        Authorization: parameters['id_token'],
      };
      try {
        let tokenVerification = await axios.get(
          'https://portal.palmettoeoc.com/prod/public/api/cognito/account',
          { headers },
        );
        if (tokenVerification && tokenVerification.data) {
          userInfoData = { ...userInfoData, ...tokenVerification.data };
          //parameters["user_id"] = tokenVerification.data.id;
          //parameters["access_token"] =  tokenVerification.data.accessToken;
          //parameters["group_id"] =  tokenVerification.data.pvActiveGroupID;
          const activeGroupID = userInfoData.pvActiveGroupID;
          let permissions = getPermissionsForSite('REP', activeGroupID, userInfoData.attributes);
          // console.log(JSON.stringify(tokenVerification.data.attributes));

          // Change to the REP types.
          switch (permissions && permissions[0]) {
            case 'F':
              repUserType = SitePermissionTypes.F;
              break;
            case 'L':
              repUserType = SitePermissionTypes.L;
              break;
            case 'C':
              repUserType = SitePermissionTypes.C;
              break;
            case 'R':
              repUserType = SitePermissionTypes.R;
              break;
            case 'G':
              repUserType = SitePermissionTypes.G;
              break;
            default:
              repUserType = null;
          }

          try {
            if (!repUserType) {
              setErrorOccurred(true);
              return setErrorMessage(
                "You don't have access to the Radiological Emergency Preparedness App.",
              );
            }

            const authData = {
              id: userInfoData.accessToken,
              userId: userInfoData.id,
              repUserType: repUserType,
            };
            sessionStorage.setItem('userAuthCreds', JSON.stringify(authData));
            // magicUrl = parameters;
          } catch (e) {
            console.log('Error occurred', e);
            return;
          }
        }
      } catch (e) {
        console.log('Error occurred', e);
        let msg = 'Failed to authorize token.';
        if (e.response && e.response.data && e.response.data.message) {
          msg = JSON.parse(JSON.stringify(e.response.data.message));
        }
        setErrorOccurred(true);
        return setErrorMessage(msg);
      }
    }

    if (sessionStorage.getItem('userAuthCreds')) {
      try {
        const authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));

        const groups = await axios.get(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/groups`);

        // if the user does not have any groups
        if (!userInfoData || !userInfoData.pvActiveGroupID) {
          setErrorOccurred(true);
          return setErrorMessage('No group found.');
        }

        // const userAccountObj = userAccountObjRes.data;
        let selectedGroup = userInfoData.pvActiveGroupID;
        const palmettoUserAccountInfo = {
          pvVoid: userInfoData.pvVoid,
          id: userInfoData.id,
          ncPersonGivenName: userInfoData.pvPersonGivenName,
          ncPersonMiddleName: userInfoData.pvPersonMiddleName,
          ncPersonSurName: userInfoData.pvPersonSurName,
          pvOfficePhone: userInfoData.pvOfficePhone,
          pvMobilePhone: userInfoData.pvMobilePhone,
          pvMultipleUser: userInfoData.pvMultipleUser,
          pvAdministrator: userInfoData.pvAdministrator,
          pvAccountLocked: userInfoData.pvAccountLocked,
          pvCanSendBlastMessage: userInfoData.pvCanSendBlastEmail,
          subscribeToEmail: userInfoData.subscribeToEmail,
          ncOrganizationUnitName: userInfoData.pvOrganizationUnitName,
          pvDomainID: userInfoData.pvDomainID,
          username: userInfoData.username,
          email: userInfoData.email,
          status: userInfoData.status,
          lastUpdated: userInfoData.lastUpdated,
          pvGroupAdmin: false,
        };

        // Check if user is group admin
        for (let i = 0; i < userInfoData.currentGroups.length; i++) {
          const group = userInfoData.currentGroups[i];
          if (Number(group.i) === Number(selectedGroup) && group.a === 1) {
            palmettoUserAccountInfo.pvGroupAdmin = true;
            break;
          }
        }

        // Just for WEBSOCKET
        window.palmettoToken = authObj;
        window.palmettoUserAccount = palmettoUserAccountInfo;
        window.activeGroup = +selectedGroup;

        let defaultPayload = {
          selectedGroupID: selectedGroup,
          accountInfo: palmettoUserAccountInfo,
          settings: [],
          groups: groups.data,
          repUserType: repUserType,
        };

        await dispatch(setDefaultInfo(defaultPayload));
        setLoginSuccessful(true);
      } catch (err) {
        console.error(err);
        setErrorOccurred(true);
        setErrorMessage('An error occurred during login.');
      }
    }
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    processLogin(); // eslint-disable-next-line
  }, []);

  const logOutUser = () => {
    sessionStorage.removeItem('userAuthCreds');
    window.location.reload();
  };

  return (
    <div className='layout vertical full-height'>
      {isSelfRegister ? (
        <SelfRegistration />
      ) : isMagicURL ? (
        <MagicUrlViewState data={magicData} />
      ) : loginSuccessful ? (
        <>{children}</>
      ) : errorOccurred ? (
        <LoginErrorMsg error={errorOccurred} message={errorMessage} logout={logOutUser} />
      ) : (
        <Login processLogin={processLogin} />
      )}
    </div>
  );
};

export default PalmettoAuthV2;
